// App.js
import './App.css';
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Navbar from './components/Navbar/Navbar'; // Import the Navbar component
import Hero from './components/Hero/Hero';
import CommentsSection from "./components/CommentSection/CommentsSection";
import GoogleMap from './components/GoogleMap/GoogleMap';
import HelpSection from './components/HelpSection/HelpSection';
import DarkModeToggle from './components/DarkModeToggle';
import ContactPage from './pages/ContactPage'; // Updated path for the ContactPage
import Footer from './components/Footer/Footer';

function App() {
  return (
    <div className="App">
      <Router>
        {/* Include Navbar here so it's present on all pages */}
        <Navbar />
        <Routes>
          {/* Home Page */}
          <Route path="/" element={
            <>
              <Hero />
              <CommentsSection />
              <HelpSection />
              <GoogleMap />
              <Footer />
              <DarkModeToggle />
            </>
          } />
          
          {/* Contact Page Route */}
          <Route path="/contact" element={<ContactPage />} />
        </Routes>
      </Router>
    </div>
  );
}

export default App;
