import React, { useState, useEffect, useRef } from 'react';
import './Hero.css'


const Hero = () => {
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [isBackground1Visible, setIsBackground1Visible] = useState(true);
  const background1Ref = useRef(null);
  const background2Ref = useRef(null);
    // Array of background images
    const images = [
      'images/back_garden.jpeg',
      'images/garden_image.jpg',
      'images/garden_tools.jpeg',
      'images/garden1.jpg',
    ];

  // Preload images function
  const preloadImages = (imageArray) => {
    imageArray.forEach((image) => {
      const img = new Image();
      img.src = image;
    });
  };

  useEffect(() => {
    // Preload images on component mount
    preloadImages(images);

    // Set interval to change background images every 3 seconds
    const intervalId = setInterval(() => {
      changeImage();
    }, 3000);

    // Clean up the interval when the component is unmounted
    return () => clearInterval(intervalId);
  }, [currentImageIndex, isBackground1Visible]);

  // Function to change the background image
  const changeImage = () => {
    if (isBackground1Visible) {
      background2Ref.current.style.backgroundImage = `url(${images[currentImageIndex]})`;
      background2Ref.current.style.opacity = 1;
      background1Ref.current.style.opacity = 0;
    } else {
      background1Ref.current.style.backgroundImage = `url(${images[currentImageIndex]})`;
      background1Ref.current.style.opacity = 1;
      background2Ref.current.style.opacity = 0;
    }

    // Increment image index and toggle visibility
    setCurrentImageIndex((currentImageIndex + 1) % images.length);
    setIsBackground1Visible(!isBackground1Visible);
  };

  return (
    <section className="hero">
      <div className="hero-background background1" ref={background1Ref}></div>
      <div className="hero-background background2" ref={background2Ref}></div>
      <div className="hero-content">
        <h1>Let us help create the garden of your dreams.</h1>
        <p>Experts in home landscaping and gardening</p>
        <button className="hero-button">Get in touch</button>
      </div>
    </section>
  );
};

export default Hero;