// HelpSection.js
import React from 'react';
import './HelpSection.css'; // Import the CSS file for styling

const HelpSection = () => {
  return (
    <section className="help-section">
      <div className="help-image-container">
        <img src="./images/garden_tools.jpeg" alt="Gardening Help" className="help-image" />
      </div>
      <div className="help-text-container">
        <h2>Need help on your garden?</h2>
        <p>Drop us a message!</p>
        <a href="#" className="help-button">Click Here</a>
      </div>
    </section>
  );
};

export default HelpSection;
