import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import './Navbar.css'; // Import the CSS for styling

const Navbar = () => {
  // State to manage the mobile menu visibility
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to handle toggling the mobile menu
  const handleToggleMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  return (
    <nav className="navbar">
      {/* Navbar Logo */}
      <div className="navbar-logo">
        <span>Sample Serenity Gardens</span>
        <span className="logo-box"></span>
      </div>

      {/* Hamburger Menu Icon for Mobile */}
      <div className="navbar-toggle" id="mobile-menu" onClick={handleToggleMenu} onTouchStart={handleToggleMenu}>
        <span className="bar"></span>
        <span className="bar"></span>
        <span className="bar"></span>
      </div>

      {/* Navbar Links */}
      <ul className={`navbar-links ${isMobileMenuOpen ? 'active' : ''}`}>
        <li><Link to="/">Home</Link></li>
        <li><Link to="/services">Services</Link></li>
        <li><Link to="/about-us">About Us</Link></li>
        <li><Link to="/gallery">Gallery</Link></li>
        <li><Link to="/testimonials">Testimonials</Link></li>
        <li><Link to="/our-environment-policies">Our Environment Policies</Link></li>
        <li><Link to="/blog">Blog</Link></li>
        <li><Link to="/contact"><button className="btn contact-us">Contact Us</button></Link></li>
      </ul>
    </nav>
  );
};

export default Navbar;
