// ContactForm.js
import React, { useEffect, useState } from 'react';
import './ContactForm.css'; // Create this file for custom styling

const ContactForm = () => {
  const [location, setLocation] = useState('');

  // Use useEffect to fetch the user's location when the component mounts
  useEffect(() => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition((position) => {
        setLocation(`${position.coords.latitude}, ${position.coords.longitude}`);
      });
    }
  }, []);

  return (
    <section id="contact" className="contact-section">
      <h2>Contact Us</h2>
      {/* Contact Form */}
      <form action="https://formspree.io/f/meojbjqe" method="POST">
        <label>
          Your email:
          <input type="email" name="email" required />
        </label>
        <label>
          Your message:
          <textarea name="message" required></textarea>
        </label>
        {/* Hidden field for the location */}
        <input type="hidden" name="location" value={location} />
        <button type="submit">Send</button>
      </form>
    </section>
  );
};

export default ContactForm;



