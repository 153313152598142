// ContactPage.js
import React from 'react';

import ContactForm from '../components/ContactForm/ContactForm';
import Footer from '../components/Footer/Footer'; // Adjust the path if necessary

const ContactPage = () => {
  return (
    <div className="contact-page">
      {/* Navbar */}
      
      {/* Contact Form Section */}
      <ContactForm />

      {/* Footer */}
      <Footer />
    </div>
  );
};

export default ContactPage;
