// GoogleMap.js
import React, { useEffect } from 'react';

const GoogleMap = () => {
  // Your API key
  const apiKey = 'AIzaSyDIodNvsGDjVKsIt2d1QjWItSzx8Bw9HCs'; // Make sure to replace this with your own Google API key

  useEffect(() => {
    // Function to load the Google Maps script
    const loadScript = (url) => {
      const script = document.createElement('script');
      script.src = url;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);
      script.onload = () => {
        initMap();
      };
    };

    // Load the Google Maps script
    loadScript(`https://maps.googleapis.com/maps/api/js?key=${apiKey}&callback=initMap`);

    // Initialize and add the map
    const initMap = () => {
      const position = { lat: 51.752022, lng: -1.257677 };

      // Create the map centered at the given position
      const map = new window.google.maps.Map(document.getElementById('map'), {
        zoom: 10,
        center: position,
      });

      // Add a marker at the position
      new window.google.maps.Marker({
        position: position,
        map: map,
        title: 'Oxford',
      });
    };

    // Clean up the script when the component is unmounted
    return () => {
      const allScripts = document.getElementsByTagName('script');
      for (let i = allScripts.length - 1; i >= 0; i--) {
        if (allScripts[i].src.includes('maps.googleapis.com')) {
          allScripts[i].remove();
        }
      }
      delete window.initMap;
    };
  }, []);

  return (
    <section className="map-section">
      <div id="map" style={{ height: '500px', width: '100%' }}></div>
    </section>
  );
};

export default GoogleMap;
