// Footer.js
import React from 'react';
import './Footer.css'; // Import the CSS file

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-container">
        {/* Left Side: Bark Logo and Operating Hours */}
        <div className="footer-column">
          <img src="./images/bark-logo.jpeg" alt="Bark Logo" className="footer-logo" />
          <div className="footer-hours">
            <h2>Operating Hours</h2>
            <p><i className="fas fa-calendar-alt"></i> Mon-Fri: 9:00 AM – 6:00 PM</p>
            <p><i className="fas fa-calendar-alt"></i> Saturday: 9:00 AM – 5:00 PM</p>
            <p><i className="fas fa-calendar-alt"></i> Sunday: Closed</p>
            <div className="social-buttons">
              <a href="#" className="facebook-button">Recommend</a>
              <a href="#" className="facebook-button">Share</a>
            </div>
          </div>
        </div>

        {/* Right Side: Checkatrade Logo and Address */}
        <div className="footer-column footer-column-right">
          <img src="./images/CAT_Approved_member_logo.jpg" alt="Checkatrade Logo" className="footer-logo" />
          <div className="footer-address">
            <p>20 Wenlock Road</p>
            <p>N1 7GU</p>
            <p>London</p>
            <p>Company Number: 15439614</p>
            <p>&copy; 2024 Oxford Serenity Gardens | All rights reserved</p>
            <div className="social-icons">
              <a href="#" className="social-icon"><i className="fab fa-facebook-f"></i></a>
              <a href="#" className="social-icon"><i className="fab fa-instagram"></i></a>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
