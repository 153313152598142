import React, { useState, useEffect } from 'react';
import './CommentsSection.css'; // Assuming you'll move your CSS into this file

const commentsData = [
  {
    content: "Great work for a great price",
    name: "Manny H",
    source: "Yell Review",
    image: "./images/review-logo.png",
  },
  {
    content: "Excellent service, very professional!",
    name: "Sarah J",
    source: "Google Review",
    image: "./images/review-logo.png",
  },
  {
    content: "Would definitely recommend to others.",
    name: "John PH",
    source: "Facebook Review",
    image: "./images/review-logo.png",
  },
];

const CommentsSection = () => {
  const [currentCommentIndex, setCurrentCommentIndex] = useState(0);

  // Function to handle next comment
  const nextComment = () => {
    setCurrentCommentIndex((prevIndex) => (prevIndex + 1) % commentsData.length);
  };

  // Function to handle previous comment
  const prevComment = () => {
    setCurrentCommentIndex((prevIndex) =>
      (prevIndex - 1 + commentsData.length) % commentsData.length
    );
  };

  // Automatically slide every 5 seconds
  useEffect(() => {
    const intervalId = setInterval(nextComment, 5000);

    // Cleanup interval on component unmount
    return () => clearInterval(intervalId);
  }, []);

  return (
    <section className="comments-section">
      <button className="arrow-left" onClick={prevComment}>&#10094;</button>
      <div className="comments-slider-wrapper">
        <div
          className="comments-slider"
          style={{ transform: `translateX(-${currentCommentIndex * 100}%)` }}
        >
          {commentsData.map((comment, index) => (
            <div className="comment" key={index}>
              <div className="comment-content">
                <p>"{comment.content}"</p>
              </div>
              <div className="comment-meta">
                <img src={comment.image} alt="Review Logo" className="review-logo" />
                <div>
                  <strong>{comment.name}</strong><br />
                  <span>{comment.source}</span>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="arrow-right" onClick={nextComment}>&#10095;</button>
    </section>
  );
};

export default CommentsSection;
